<div class="w-full mb-2">
  <div [class.notif-item-read]="isRead" class="p-2  border-round  notif-item">
    <div class="flex align-items-center"><img class="mr-2 w-2rem"
                                              src="/assets/images/notification/package_expire.png"/>
      <span class="font-medium text-base">Christina’s package has <b>expired</b>.</span>

      <img class="ml-2 w-1rem" src="/assets/images/notification/notif_go_to.png"/>
    </div>
  </div>
</div>
